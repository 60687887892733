@import '@/components/mixins.scss';

//////////////////////////////////////////////////////////////////////////////////////////
/* AUTH */

.container {
  padding: rem(50) rem(30);
}

.lh15 {
  line-height: 15px !important;
}